<template>
  <div class="gg-container-1">
    <div class="top-content">
      <div><span style="border-bottom:3px solid #1684FC">会员详情</span></div>
      <div class="content">
        <img
          :src="info.head_portrait"
          style="width:60px;height:60px;border-radius: 50%;margin-right:20px;"
        />
        <div class="content-right">
          <span style="font-weight: bold">{{ info.nickname }}</span>
          <div style="line-height:3;margin-top:10px;">
            <span>会员ID：{{ info.member_id }}</span>
            <span style="margin-left: 20px">手机号：{{ info.mobile }}</span>
          </div>
          <div style="line-height:3">
            <span>创建时间：{{ info.created_at }}</span>
            <span style="margin-left: 20px"
              >最后登录时间：{{ info.last_time }}</span
            >
          </div>
          <div style="line-height:3">
            <span>会员等级：{{ info.vip_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="search-container" style="margin-top:40px;">
      <div><span style="border-bottom:3px solid #1684FC">消费记录</span></div>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <!-- <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleLook(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >查看
          </el-button>
        </div> -->
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="420"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.doctors_id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true &&
              column.column_label == '医师头像'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              v-if="
                column.column_prop == 'druggist_one_name' && admin_type != 2
              "
            >
              {{ scope.row.druggist_one_name }}
            </a>
            <a
              v-else-if="
                column.column_prop == 'druggist_second_name' && admin_type != 2
              "
            >
              {{ scope.row.druggist_second_name }}
            </a>
            <a v-else-if="column.column_prop == 'type'">
              {{ scope.row.type == 1 ? "成药开方" : "中药开方" }}
            </a>
            <a v-else-if="column.column_prop == 'is_show'">
              <el-switch
                active-text="展示"
                class="switch"
                inactive-text="隐藏"
                v-model="scope.row.is_show"
                active-color="#13ce66"
                inactive-color="#c8c8c8"
                :active-value="1"
                :inactive-value="0"
                @change="
                  institutionalStatus(scope.row.prescription_id, scope.row)
                "
              >
              </el-switch>
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'nickname' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.nickname"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.nickname)
                "
              >
              </el-input>
              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
      <memberInfoTableHead
        :isActiveMemberInfoTableHead.sync="isActiveMemberInfoTableHead"
        @_getAdminFieldIndex="_getAdminFieldIndex"
        v-if="isActiveMemberInfoTableHead"
        @getList="getList"
      >
      </memberInfoTableHead>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import memberInfoTableHead from "../tableHead/memberInfoTableHead.vue";
import { getMemberConumeList, getMemberInfo } from "@/newApi/member";
import { Message } from "element-ui";

export default {
  name: "memberList",
  data() {
    return {
      id: null,
      isActiveMemberInfoTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "nickname",
          isSearch: false,
        },
        {
          name: "mobile",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      info: {}, //详情
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
    };
  },
  components: {
    Pagination,
    memberInfoTableHead,
  },
  created() {
    setTimeout(() => {
      this._getAdminFieldIndex();
      this.getList();
      this.getInfo();
    }, 100);
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveMemberInfoTableHead = true;
    },
    async _getAdminFieldIndex() {
      try {
        this.listLoading = true;
        var params = {
          type: "memberInfo",
        };
        const res = await getAdminFieldIndex(params);
        this.tableHead = res.data;
        console.log(this.tableHead);
      } catch (err) {
        //在此处理错误
      } finally {
        this.listLoading = false;
      }
    },
    getInfo() {
      this.fullscreenLoading = true;
      let params = {
        id: this.id,
      };
      getMemberInfo(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.info = _data;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;
      this.searchParams.id = this.id;
      getMemberConumeList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.gg-container-1 {
  padding: 30px;
}
.top-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  .content {
    margin-top: 30px;
    display: flex;
    .content-right {
      display: flex;
      flex-direction: column;
    }
  }
}
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>
