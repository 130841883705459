<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('view') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleLook(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >查看
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.doctors_id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true &&
              column.column_label == '医师头像'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              v-if="
                column.column_prop == 'druggist_one_name' && admin_type != 2
              "
            >
              {{ scope.row.druggist_one_name }}
            </a>
            <a
              v-else-if="
                column.column_prop == 'druggist_second_name' && admin_type != 2
              "
            >
              {{ scope.row.druggist_second_name }}
            </a>
            <a v-else-if="column.column_prop == 'type'">
              {{ scope.row.type == 1 ? "成药开方" : "中药开方" }}
            </a>
            <a v-else-if="column.column_prop == 'is_show'">
              <el-switch
                active-text="展示"
                class="switch"
                inactive-text="隐藏"
                v-model="scope.row.is_show"
                active-color="#13ce66"
                inactive-color="#c8c8c8"
                :active-value="1"
                :inactive-value="0"
                @change="
                  institutionalStatus(scope.row.prescription_id, scope.row)
                "
              >
              </el-switch>
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'nickname' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.nickname"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.nickname)
                "
              >
              </el-input>
              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-drawer
      :append-to-body="true"
      size="72%"
      :visible.sync="dialogTableVisible"
      direction="rtl"
      :modal="false"
    >
      <memberInfo
        ref="memberInfo"
        @getList="getList"
        v-if="dialogTableVisible"
      ></memberInfo>
    </el-drawer>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import memberInfo from "./components/memberInfo.vue";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import { getMenberList } from "@/newApi/member";
import { Message } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "memberList",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  computed:{
    ...mapState({
      table_options: state => state.user.table_options,
    })

  },
  data() {
    return {
      table_type:'memberList',
      operateList: [],
      dialogTableVisible: false,
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "nickname",
          isSearch: false,
        },
        {
          name: "mobile",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
    };
  },
  components: {
    Pagination,
    editTableHead,
    memberInfo,
  },

  methods: {
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "memberList",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.memberList;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;

      getMenberList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    //查看
    handleLook(row) {
      console.log(row, "查看详情");
      //跳转详情页
      this.dialogTableVisible = true;
      setTimeout(() => {
        this.$refs.memberInfo.id = row.id;
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>
